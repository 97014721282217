import { Component } from 'react';
import styled from 'styled-components';
import { Cookie, load as cookieLoad, save as cookieSave } from '@kiwicom/cookies';
import * as logger from '@kiwicom/nitro/lib/services/log/logger';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AuthToken } from '@kiwicom/nitro/lib/records/Auth';
import { CurrencyProvider } from '@kiwicom/nitro/lib/services/currency/context';
import InitCurrency from '@kiwicom/nitro/lib/components/InitCurrency';
import { type Session, sessionDefault } from '@kiwicom/nitro/lib/records/Session';
import initSession from '@kiwicom/nitro/lib/services/session/init';
import { Provider as SessionProvider } from '@kiwicom/nitro/lib/services/session/context';
import useOAuthRedirect from '@kiwicom/nitro/lib/services/hooks/useOAuthRedirect';
import MagicLoginRenderer from '@kiwicom/nitro/lib/components/MagicLogin/Renderer';
import type { NitroData } from '@data-fetching/loadNitroData';
import { getPlatform } from '@kiwicom/nitro/lib/services/platform/client';

import TopNavComponent from './TopNavComponent';

type State = {
  readonly appData: NitroData | null;
  readonly token: string | null;
  readonly session: Session;
};

type Props = Readonly<{
  readonly nitroData: NitroData;
  readonly isWebview: boolean;
  readonly useSimplifiedLoginFlow?: boolean;
}>;

const handleChangeCurrency = (currency: string) => {
  cookieSave(Cookie.CURRENCY, currency);
  window.location.reload();
};

const MagicLoginWrapper = styled.div`
  [data-test='MagicLogin'] {
    z-index: ${({ theme }) => Number(theme.orbit.zIndexDrawer) + 1};
  }
`;

const OAuthRedirect = () => {
  useOAuthRedirect();
  return null;
};
class NitroBar extends Component<Props, State> {
  state: State = {
    appData: null,
    token: null,
    session: {
      userId: '',
      sessionId: '',
      pageViewId: '',
      deeplinkId: '',
      affiliate: null,
      UTMs: {},
    },
  };

  componentDidMount() {
    const { nitroData } = this.props;
    const getSessionInfo = (): Session => {
      try {
        return initSession();
      } catch (e) {
        return sessionDefault;
      }
    };

    const session = getSessionInfo();

    const token = cookieLoad(Cookie.USER_ID) ?? null;
    const platform = getPlatform();

    logger.init({
      project: 'frontend',
      module: 'account',
      pageName: '',
      langId: nitroData.intlRaw.language.id ?? '',
      pageViewId: session.pageViewId ?? '',
      brandingId: nitroData.brand.id ?? '',
      affilParams: session.affiliate?.params ?? {},
      UTMs: session.UTMs ?? {},
      platform,
    });
    logger.settings.moduleVersion = `${process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA}`;

    this.setState({
      token,
      session,
      appData: nitroData,
    });
  }

  handleToken = ({ token, bid }: AuthToken) => {
    const id = this.state.appData.intlRaw.language.id || 'en';
    window.location.href = `/${id}/manage/${bid}/${token}`;
  };

  handleRedirectHome = () => {
    const id = this.state.appData.intlRaw.language.id || 'en';
    window.location.href = `/${id}/`;
  };

  handleOpenHelpPage = () => {
    const id = this.state.appData.intlRaw.language.id || 'en';
    window.open(`/${id}/help/contact/`, '_blank');
  };

  render() {
    const { nitroData, isWebview, useSimplifiedLoginFlow } = this.props;
    const { session, appData } = this.state;
    const { intlRaw, brand, fetched, ip }: NitroData = appData || nitroData;

    return (
      <SessionProvider value={session}>
        <InitCurrency
          brand={brand}
          countries={fetched.countries}
          affiliate={session.affiliate?.id ?? ''}
          ip={ip}
          langCurrency={intlRaw.language.currency}
          onChange={handleChangeCurrency}
        >
          {(currency) => {
            return (
              <CurrencyProvider value={currency}>
                <>
                  <OAuthRedirect />
                  {!isWebview && (
                    <header>
                      <TopNavComponent
                        brandLanguage={fetched.brandLanguage}
                        onOpenHelp={this.handleOpenHelpPage}
                        subscriptionModalContent={null}
                        modalId="modals"
                      />
                    </header>
                  )}
                  <MagicLoginWrapper>
                    <MagicLoginRenderer
                      isSimplifiedLoginFlow={useSimplifiedLoginFlow ?? true}
                      origin="account"
                      onGetSimpleToken={this.handleToken}
                    />
                  </MagicLoginWrapper>
                </>
              </CurrencyProvider>
            );
          }}
        </InitCurrency>
      </SessionProvider>
    );
  }
}

export default NitroBar;
